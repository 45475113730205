import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage3Cookie_note from "./pages/Webpage3Cookie_note";
import Webpage4Page_not_found from "./pages/Webpage4Page_not_found";
import Webpage9Men_____vaated from "./pages/Webpage9Men_____vaated";
import Webpage11Menu_templates from "./pages/Webpage11Menu_templates";
import Webpage13Home from "./pages/Webpage13Home";

var baseURL = 'https://lilleorumtu.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNTk3NTE0NTJhNjUzNzg2ODQ0MjQyNDUwYjJhYmE5OTU0MDQzNTBhOWMwYTc4MTI4YWY5MzIwZDdmNTQ1ZjFmMmMxOTI4ODg5MmMxODNiZTAiLCJpYXQiOjE3MzYxNzc4NzIuNjY2NDU3LCJuYmYiOjE3MzYxNzc4NzIuNjY2NDYzLCJleHAiOjE3MzYyNjQyNzIuNjYyMDkxLCJzdWIiOiI2NyIsInNjb3BlcyI6W119.BFb-rJ_PW49rWkVmxzDLAD_UvrurpcrhxWXoxm-BK0hTYbTkFXSAiIe4hcou71Y6l3snu2cONfLgir_OyUdiWRVALiLSbpfoNQkWe1kuinym1gNUOy4MYw1adfyoFj62nd_X3PIdZWFXaxbjZFb_f4Hs-9wL_v8bjfCVRAxBfKWjlaH6c1cbcqKPyVtRoC7P_S5tlpY7l_4rvvAgTO_ezGsg4-_amyMDeoXgNfiuOsgDHX_-_sjPs8HZlcLQ7QRTde45WOCl5IVuCCv6kR6znupme8yMi6kjz18DvN5pEVH74PMw2hb4AoeFBci0en-VfTRFTpGVeW9NruhtJny-X2X6EYW_yBlKBnnFrM4rUF2yY1UVkerEFw0Wp1KZW8qo3PHOWoe5yhqg4K7eVOw3TufZB8tssLAkJvDFc5hYhcMteAgGoo66i-AM7dW_kuMI4WNviVnUBHQa4M96Is9zFNWCp_lNXNnxmQ36bUZk9CDNgV08qLm3Bfl_yYqW2bdfuYDjaf44Ds4kipyWlfOISxTeI2ncQ_eYAjjvAgHgfVbp4R6THPWC7CIWRf8kiEKXpBhhwxxqKeBumf0OeYH7xc4NCsqpWqG4M9eYhYjx7eSkQwQYcx_Jp_96uE6S2zwGy7UcRTR_PaZjAq7rec0sXxqzMoTBj8fNn8P14LgZi68',
    'Access-api-key': "eyJpdiI6ImFpdmx2c1lFQUtNOHZYSGxFWkZBYXc9PSIsInZhbHVlIjoidDVPREttek5NNW5SWFFTYzRhSzFQQT09IiwibWFjIjoiNzZiYjM4N2Q3MDM3MjJiNDcxZDA5MDY0MmYwMDEyZmFhNDJjNzE1NDYzMDg4MmY3YzQyMjFjMDQ4OWU0MzJlNCIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IlV4aUwwSHo5VFVrRmMxL25zaTdOREE9PSIsInZhbHVlIjoiTWtwbGoxYVFGeHFjUjZIZ2NSRzhGQT09IiwibWFjIjoiOTczYzNlY2ZlYzI5N2MzMWIyMmUwYzdiMDM5OTgzZjZhMDEyZTlkYWZiMzBhYjViYTcwYTIxZmFkYTQ0YTU2ZiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/en/cookie-note" element={<Webpage3Cookie_note />} exact />;
                                                                    <Route path="/en/*" element={<Webpage4Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage4Page_not_found />} exact />;
                                                                    <Route path="/et/templates" element={<Webpage9Men_____vaated />} exact />;
                                                                    <Route path="/en/templates" element={<Webpage11Menu_templates />} exact />;
                                                                    <Route path="/en/" element={<Webpage13Home />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}